import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/css/App.css";
import Home from "./pages/Home";
import ClassPage from "./pages/ClassPage";
import SubjectPage from "./pages/SubjectPage";
import ChapterPage from "./pages/ChapterPage";
import DownloadPage from "./pages/DownloadPage";
import CookiesEnabledCheck from "./higherOrderComponents/CookiesEnbledCheck";
import BlockerPage from "./pages/BlockerPage";
import ErrorCookies from "./pages/Errors/ErrorCookies";
import Page404 from "./pages/Page404";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            children={<CookiesEnabledCheck Children={Home} />}
          />
          <Route
            exact
            path="/class/:standard"
            children={<CookiesEnabledCheck Children={ClassPage} />}
          />
          <Route
            exact
            path="/subject/:standard/:subject"
            children={<CookiesEnabledCheck Children={SubjectPage} />}
          />
          <Route
            exact
            path="/chapter/:standard/:subject/:chapter"
            children={<CookiesEnabledCheck Children={ChapterPage} />}
          />
          <Route
            exact
            path="/download/:standard/:subject/:chapter/:type/:ind/:showIndex"
            children={<CookiesEnabledCheck Children={DownloadPage} />}
          />
          <Route
            exact
            path="/blocker"
            children={<CookiesEnabledCheck Children={BlockerPage} />}
          />
        </Switch>
        <Route exact path="/error/cookies" children={<ErrorCookies />} />

        {/* <Route exact children={<Page404 />} /> */}
      </Router>
    </div>
  );
}

export default App;
