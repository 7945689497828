import React from "react";
import { Link } from "react-router-dom";
import logoBlackText from "../assets/images/logo_header.png";
import logoWhiteText from "../assets/images/logo_header_white.png";
import playLogo from "../assets/images/google-play-badge.png";

const ViewBoxContainer = ({ children }) => {
  return (
    <div>
      <div className="w-screen h-screen bg-white flex items-center justify-center">
        <div className=" viewBox bg-white overflow-scroll">
          <div
            style={{ height: "10vh" }}
            className="w-full shadow-xl flex items-center px-4 py-2"
          >
            <Link to="/" className="mr-auto flex items-center justify-center">
              <img src={logoBlackText} className="w-auto h-10" />
            </Link>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=ai.homework.app.ncert.cbse.maths.science"
            >
              <img src={playLogo} className="h-10 w-auto ml-auto" />
            </a>
          </div>
          <div style={{ minHeight: "85vh" }}>{children}</div>

          <div
            style={{ backgroundColor: "#555555" }}
            className=" w-full flex flex-col p-3"
          >
            <a
              className=" w-6/12 flex items-center my-4"
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=ai.homework.app.ncert.cbse.maths.science"
            >
              <img
                style={{ maxWidth: "none" }}
                src={logoWhiteText}
                className="w-48 h-auto "
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=ai.homework.app.ncert.cbse.maths.science"
            >
              <img src={playLogo} className="w-48 h-auto " />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBoxContainer;
