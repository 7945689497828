import React from "react";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useUpdateChecker from "../../customHooks/useUpdateCheck";
import ViewBoxContainer from "../../Layout/ViewBoxContainer";
import { FSStoreInstance } from "../../stores/FSstore";
import { userDataInstance } from "../../stores/userDataStore";
import { DefaultConfigValues } from "../../utils/Consts";

const LoginWithPhoneNumber = observer(() => {
  const history = useHistory();

  const storePhoneNumber = (value) => userDataInstance.storePhoneNumber(value);
  const sendOtpToNo = (value) => userDataInstance.sendOtpToNo(value);
  const register = (userName, entity) =>
    userDataInstance.register(userName, entity);
  const noBlocker = useUpdateChecker(userDataInstance.noBlocker);
  const configValues = useUpdateChecker(FSStoreInstance.ConfigValues);

  const entityList =
    configValues === undefined || configValues === null
      ? DefaultConfigValues.entityList
      : configValues.entityList;

  const phoneNoInputRef = useRef();
  const phonNoEntryRef = useRef();

  const otpInputRef = useRef();
  const otpEntryRef = useRef();

  const dropDownRef = useRef();

  const verifyingRef = useRef();

  const dataEntryRef = useRef();
  const userNameRef = useRef();

  const errRef = useRef();

  const resendRef = useRef();

  const [buttonColour1, setButtonColor1] = useState(false);
  const [buttonColour2, setButtonColor2] = useState(false);
  const [buttonColour3, setButtonColor3] = useState(false);

  if (noBlocker) {
    history.goBack();
  }

  let resendTimerId = undefined;

  const startResendTimer = () => {
    if (resendRef.current !== undefined && resendTimerId === undefined) {
      console.log("starting timer");

      resendRef.current.style.width = "40px";
      resendTimerId = setTimeout(function loop() {
        if (resendRef.current !== undefined && resendRef.current !== null) {
          let currentTime = resendRef.current.innerHTML;
          if (currentTime > 0) {
            resendRef.current.innerHTML = currentTime - 1;
            resendTimerId = setTimeout(loop, 1000);
          } else {
            resendRef.current.style.width = "auto";
            resendRef.current.innerHTML = "Resend OTP";
            resendTimerId = undefined;
          }
        } else {
          resendTimerId = setTimeout(loop, 1000);
        }
      }, 0);
    }
  };

  const stopResendCounter = () => {
    if (resendTimerId) {
      clearInterval(resendTimerId);
    }
  };

  const resendOTP = (e) => {
    if (e.target.innerHTML === "Resend OTP") {
      sendOtpToNo(phoneNoInputRef.current.value);
      startResendTimer();
    } else {
      console.log("wait");
    }
  };

  const handleChange = (e) => {
    //console.log(e.target.name);
    if (e.target.name === "number") {
      if (e.target.value.length === 10) {
        setButtonColor1(true);
      } else {
        setButtonColor1(false);
      }
    } else if (e.target.name === "OTP") {
      if (e.target.value.length === 4) {
        setButtonColor2(true);
      } else {
        setButtonColor2(false);
      }
    } else if (e.target.name === "userName") {
      const selectedEntity =
        dropDownRef.current.options[dropDownRef.current.selectedIndex].value;
      if (e.target.value.length > 0 && selectedEntity !== "DEFAULT") {
        setButtonColor3(true);
      } else {
        setButtonColor3(false);
      }
    } else if (e.target.name === "userType") {
      if (
        e.target.value !== "noneSelected" &&
        userNameRef.current.value &&
        userNameRef.current.value.length > 0
      ) {
        setButtonColor3(true);
      } else {
        setButtonColor3(false);
      }
    }
  };
  const handleClick = (type) => {
    const phoneNo = phoneNoInputRef.current.value;

    if (type === "phoneNoButton") {
      if (phoneNo.length === 10) {
        //page change
        phonNoEntryRef.current.style.display = "none";
        otpEntryRef.current.style.display = "block";

        //otp sending logic
        sendOtpToNo(phoneNo);
        startResendTimer();
      }
    } else if (type === "otpButton") {
      const OTP = otpInputRef.current.value;
      if (OTP.length === 4) {
        //page change

        //otp verifying logic
        //console.log(OTP,FSStoreInstance.otp);
        if (OTP == userDataInstance.otp) {
          storePhoneNumber(phoneNo);
          console.log("phone verified");
          otpEntryRef.current.style.display = "none";
          dataEntryRef.current.style.display = "block";
        } else {
          //console.log("wrong");
          errRef.current.style.display = "block";
        }
      }
    } else if (type === "dataEntryButton") {
      const userName = userNameRef.current.value;
      const selectedEntity =
        dropDownRef.current.options[dropDownRef.current.selectedIndex].value;
      if (userName.length > 0) {
        register(userName, selectedEntity);
        history.goBack();
      }
    }
  };

  useEffect(() => {
    return () => {
      stopResendCounter();
    };
  }, []);
  return (
    <ViewBoxContainer>
      <div
        style={{ height: "90vh" }}
        className="w-full flex py-32 justify-center items-center bg-gray-500"
      >
        <div
          ref={phonNoEntryRef}
          className="w-11/12 bg-white h-64 rounded-xl flex flex-col items-center shadow-2xl "
        >
          <p className=" w-11/12 my-3 text-md font-semibold text-xl text-gray-500">
            {" "}
            Please login to continue{" "}
          </p>
          <div style={{ height: "1px" }} className="bg-gray-500 w-11/12"></div>
          <p className=" w-11/12 my-3 text-md text-gray-500">
            {" "}
            What's your phone number?
          </p>

          <div className="flex justify-center items-center w-11/12 ">
            <div className="flex justify-center items-center border-2 border-gray-500 rounded-lg w-2/12 h-12 text-gray-500 mr-4">
              +91
            </div>
            <input
              ref={phoneNoInputRef}
              onChange={handleChange}
              type="number"
              placeholder="Enter 10 digit number"
              name="number"
              className="border-2 border-gray-500 rounded-lg w-10/12 my-3 h-12 px-4 text-gray-500"
            />
          </div>

          <div className=" w-11/12 my-3">
            <div
              style={{
                backgroundColor: buttonColour1 ? "#fc4674" : "#555555",
              }}
              className=" w-5/12 rounded-xl p-3 text-center"
              onClick={() => handleClick("phoneNoButton")}
            >
              <p className="text-white text-xl ">Continue</p>
            </div>
          </div>
        </div>

        <div
          ref={otpEntryRef}
          style={{ display: "none" }}
          className="w-11/12 bg-white h-64 rounded-xl flex flex-col items-center shadow-2xl px-4"
        >
          <p className=" w-11/12 my-3 text-md font-semibold text-xl text-gray-500">
            {" "}
            Please login to continue{" "}
          </p>
          <div style={{ height: "1px" }} className="bg-gray-500 w-full"></div>
          <div className="flex w-1full mt-3">
            <p className=" text-md text-gray-500"> Enter the OTP </p>
            <span
              ref={errRef}
              style={{ display: "none" }}
              className="text-red-500 font-semibold ml-auto"
            >
              {" "}
              Incorrect OTP Entered
            </span>
          </div>

          <div className="flex items-center w-full ">
            <input
              ref={otpInputRef}
              onChange={handleChange}
              type="number"
              placeholder="4-digit OTP"
              name="OTP"
              className="border-2 border-gray-500 rounded-lg w-6/12 my-3 h-12 px-4 text-gray-500 text-center"
            />
          </div>

          <div className=" w-full my-3 flex justify-center">
            <button
              style={{
                backgroundColor: buttonColour2 ? "#fc4674" : "#555555",
              }}
              className=" rounded-xl p-3 text-center text-white text-lg"
              onClick={() => handleClick("otpButton")}
            >
              Continue
            </button>
            <button
              ref={resendRef}
              style={{ color: "#555", padding: "9px" }}
              className=" rounded-xl text-center text-lg ml-auto font-bold border-2 border-gray-300"
              onClick={resendOTP}
            >
              60
            </button>
          </div>
        </div>

        <div
          ref={dataEntryRef}
          style={{ display: "none" }}
          className="w-11/12 bg-white h-80 rounded-xl flex-col shadow-2xl px-4"
        >
          <p className=" w-11/12 my-3 text-md font-semibold text-xl text-gray-500">
            {" "}
            Please enter your details{" "}
          </p>
          <div style={{ height: "1px" }} className="bg-gray-500 w-11/12"></div>

          <p className="text-md font-semibold text-md text-gray-500 mt-2">
            What's your name
          </p>
          <input
            ref={userNameRef}
            onChange={handleChange}
            type="text"
            placeholder="Enter Name"
            name="userName"
            className="border-2 border-gray-500 rounded-lg w-full my-2 h-12 px-4 text-gray-500"
          />

          <label
            htmlFor="userType"
            className="text-md font-semibold text-md text-gray-500 mt-2"
          >
            Who are you?
          </label>

          <select
            ref={dropDownRef}
            name="userType"
            id="userType"
            defaultValue={"DEFAULT"}
            onChange={handleChange}
            className=" bg-white  border-2 border-gray-500 rounded-lg w-full my-3 h-12 px-3 text-gray-500 text-center"
          >
            <option value="DEFAULT" disabled hidden>
              Select an Option
            </option>
            {entityList.map((inst) => {
              return (
                <option value={inst} key={inst}>
                  {inst}
                </option>
              );
            })}
          </select>

          <div className=" w-11/12 my-3">
            <div
              style={{
                backgroundColor: buttonColour3 ? "#fc4674" : "#555555",
              }}
              className=" w-5/12 rounded-xl p-3 text-center"
              onClick={() => handleClick("dataEntryButton")}
            >
              <p className="text-white text-xl ">Continue</p>
            </div>
          </div>
        </div>

        <div
          ref={verifyingRef}
          style={{ display: "none" }}
          className="w-11/12 bg-white h-64 rounded-xl flex flex-col items-center shadow-2xl px-4 justify-center items-center"
        >
          <div>Verifying</div>
        </div>
      </div>
    </ViewBoxContainer>
  );
});

export default LoginWithPhoneNumber;
