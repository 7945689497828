export const startMillis = 1616931519764;

export const gradients = [
  { from: "#b884ff", to: "#594bc8" },
  { from: "#fa7b7b", to: "#fc4674" },
  { from: "#4eb25a", to: "#369041" },
  { from: "#5ad6b5", to: "#42c6a3" },
  { from: "#ffa768", to: "#eb6f15" },
  { from: "#ea70ac", to: "#864766" },
  { from: "#ffde5a", to: "#e0b60e" },
];

export const DefaultDownloadPageValue = {
  image:
    "https://case-study-questions.s3.ap-south-1.amazonaws.com/brand-sets/1/logo+%E2%80%93+hw+%E2%80%93+free+-+256.png",
  title: "Want access to 5lac+ questions?",
  body:
    "Create homework / worksheet / quiz from 5lac+ pre-typed questions from the best books",
  cta: "Download the homework app",
  cta_url:
    "https://play.google.com/store/apps/details?id=ai.homework.app.ncert.cbse.maths.science",
};

export const DefaultConfigValues = {
  startDelay: "4",
  backDelay: "5",
  loginBlocker: 1,
  shareBlocker: -1,
  entityList: [
    "Teacher",
    "Student",
    "Principal",
    "HOD",
    "Vice Principal",
    "Superviser",
    "None",
  ],
};

export const DefaultRCValue = {
  files: [
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Answer%20key-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Answer%20key-2%20.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Answer%20key-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Answer%20key-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Answer%20Key-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Worksheet-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Worksheet-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Worksheet-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Worksheet-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/A%20and%20An/Worksheet-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "A and An",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Alphabet%20and%20Letters/Answer%20Key%20-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Alphabet and Letters",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Alphabet%20and%20Letters/Answer%20Key-2%20.pdf",
      class: "1st",
      subject: "English",
      chapter: "Alphabet and Letters",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Alphabet%20and%20Letters/Worksheet-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Alphabet and Letters",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Alphabet%20and%20Letters/Worksheet-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "Alphabet and Letters",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Answer%20Key-%202.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Answer%20Key-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Answer%20Key-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Answer%20Key-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Answerkey%20-1%20.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Worksheet-1%20.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Worksheet-2%20.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Worksheet-3%20.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Worksheet-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Me,%20my%20family%20and%20my%20home/Worksheet-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Me, my family and my home",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Answer%20Key-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Answer%20Key-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Answer%20Key-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Answer%20Key-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Answer%20Key-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Worksheet-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Worksheet-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Worksheet-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Worksheet-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Nouns/Worksheet-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Nouns",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Answer%20Key-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Answer%20Key-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Answer%20Key-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Answer%20Key-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Answer%20Key-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Worksheet-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Worksheet-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Worksheet-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Worksheet-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Sentences/Worksheet-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Sentences",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-6.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "6",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-7.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "7",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Answer%20Key-8.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "8",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-1.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-2.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-3.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-4.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-5.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-6.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-7.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/English/Vocabulary%20Building/Worksheet-8.pdf",
      class: "1st",
      subject: "English",
      chapter: "Vocabulary Building",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Answer%20Key-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Answer%20Key-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Answer%20Key-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Answer%20Key-5.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Worksheet-1.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Worksheet-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Worksheet-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Worksheet-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Addition/Worksheet-5.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Addition",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Answer%20Key%20-5.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Answer%20Key-1.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Answer%20Key-2%20.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Answer%20Key-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Answer%20Key-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Worksheet%20-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Worksheet-%205.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Worksheet-1.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Worksheet-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Multiplication/Worksheet-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Multiplication",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Answer%20Key-1.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Answer%20Key-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Answer%20Key-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Worksheet-1.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Worksheet-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Worksheet-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Worksheet-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Ordering%20Numbers/Worksheet-5.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Ordering Numbers",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Answer%20Key-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Answer%20Key-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Answer%20Key-5.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Worksheet-1.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Worksheet-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Worksheet-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Worksheet-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Subtraction/Worksheet-5.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Subtraction",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Time/Answer%20Key-1.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Time",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Time/Answer%20Key-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Time",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Time/Worksheet%20-3.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Time",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Time/Worksheet-1%20.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Time",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Time/Worksheet-2.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Time",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Time/Worksheet-4.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Time",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/1st/Math/Time/Worksheet-5.pdf",
      class: "1st",
      subject: "Math",
      chapter: "Time",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Answer%20Key-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Answer%20Key-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Answer%20Key-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Answer%20Key-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Answer%20Key-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Worksheet-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Worksheet-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Worksheet-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Worksheet-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Addition/Worksheet-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Addition",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Answer%20Key%20-3%20.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Answer%20Key-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Answer%20Key-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Answer%20Key-4%20.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Answer%20Key-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Worksheet-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Worksheet-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Worksheet-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Worksheet-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Division/Worksheet-5%20.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Division",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Answer%20Key-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Answer%20Key-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Answer%20Key-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Answer%20Key-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Answer%20Key-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Worksheet-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Worksheet-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Worksheet-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Worksheet-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Multiplication/Worksheet-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Multiplication",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Answer%20Key-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Answer%20Key-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Answer%20Key-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Answer%20Key-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Answer%20Key-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Worksheet-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Worksheet-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Worksheet-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Worksheet-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Subtraction/Worksheet-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Subtraction",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Answer%20Key-1%20.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "1",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Answer%20Key-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "2",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Answer%20Key-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "3",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Answer%20Key-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "4",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Answer%20Key-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "5",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Answer%20Key-6.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "6",
      type: "answersheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Worksheet-1.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Worksheet-2.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Worksheet-3.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Worksheet-4.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Worksheet-5.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/2nd/Math/Three%20digit%20numbers/Worksheet-6.pdf",
      class: "2nd",
      subject: "Math",
      chapter: "Three digit numbers",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Compound%20words/Worksheet-1.pdf",
      class: "KG",
      subject: "English",
      chapter: "Compound words",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Compound%20words/Worksheet-2.pdf",
      class: "KG",
      subject: "English",
      chapter: "Compound words",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Compound%20words/Worksheet-3.pdf",
      class: "KG",
      subject: "English",
      chapter: "Compound words",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Compound%20words/Worksheet-4.pdf",
      class: "KG",
      subject: "English",
      chapter: "Compound words",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Compound%20words/Worksheet-5.pdf",
      class: "KG",
      subject: "English",
      chapter: "Compound words",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Compound%20words/Worksheet-6.pdf",
      class: "KG",
      subject: "English",
      chapter: "Compound words",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Compound%20words/Worksheet-7.pdf",
      class: "KG",
      subject: "English",
      chapter: "Compound words",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-1.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-2.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-3.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-4.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-5.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-6.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-7.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-8.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Me%20and%20my%20family/Worksheet-9.pdf",
      class: "KG",
      subject: "English",
      chapter: "Me and my family",
      set: "9",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Singular%20and%20plural/Worksheet-1.pdf",
      class: "KG",
      subject: "English",
      chapter: "Singular and plural",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Singular%20and%20plural/Worksheet-2.pdf",
      class: "KG",
      subject: "English",
      chapter: "Singular and plural",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Singular%20and%20plural/Worksheet-3.pdf",
      class: "KG",
      subject: "English",
      chapter: "Singular and plural",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Singular%20and%20plural/Worksheet-4.pdf",
      class: "KG",
      subject: "English",
      chapter: "Singular and plural",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-1.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-2.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-3.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-4.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-5.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-6.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-7.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Three%20letter%20words/Worksheet-8.pdf",
      class: "KG",
      subject: "English",
      chapter: "Three letter words",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Vowels%20_%20Consonants/Worksheet-1.pdf",
      class: "KG",
      subject: "English",
      chapter: "Vowels _ Consonants",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Vowels%20_%20Consonants/Worksheet-2.pdf",
      class: "KG",
      subject: "English",
      chapter: "Vowels _ Consonants",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Vowels%20_%20Consonants/Worksheet-3.pdf",
      class: "KG",
      subject: "English",
      chapter: "Vowels _ Consonants",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Vowels%20_%20Consonants/Worksheet-4.pdf",
      class: "KG",
      subject: "English",
      chapter: "Vowels _ Consonants",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/English/Vowels%20_%20Consonants/Worksheet-5.pdf",
      class: "KG",
      subject: "English",
      chapter: "Vowels _ Consonants",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Addition/Worksheet-1.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Addition",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Addition/Worksheet-2.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Addition",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Addition/Worksheet-3.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Addition",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Addition/Worksheet-4.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Addition",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Addition/Worksheet-5.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Addition",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Addition/Worksheet-6.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Addition",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Addition/Worksheet-7.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Addition",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Before,%20After%20and%20in-%20between/Worksheet-1.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Before, After and in- between",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Before,%20After%20and%20in-%20between/Worksheet-2.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Before, After and in- between",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Before,%20After%20and%20in-%20between/Worksheet-3.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Before, After and in- between",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Before,%20After%20and%20in-%20between/Worksheet-4.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Before, After and in- between",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Before,%20After%20and%20in-%20between/Worksheet-5.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Before, After and in- between",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Big%20_%20Small/Worksheet-1.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Big _ Small",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Big%20_%20Small/Worksheet-2.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Big _ Small",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Big%20_%20Small/Worksheet-3.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Big _ Small",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Big%20_%20Small/Worksheet-4.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Big _ Small",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Big%20_%20Small/Worksheet-5.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Big _ Small",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Big%20_%20Small/Worksheet-6.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Big _ Small",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Big%20_%20Small/Worksheet-7.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Big _ Small",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Skip%20counting/Worksheet-1.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Skip counting",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Skip%20counting/Worksheet-2.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Skip counting",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Skip%20counting/Worksheet-3.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Skip counting",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Skip%20counting/Worksheet-4.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Skip counting",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Skip%20counting/Worksheet-5.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Skip counting",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Skip%20counting/Worksheet-6.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Skip counting",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet%20-5.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet-1.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet-2.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet-3.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet-4.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet-6.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet-7.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/KG/Math/Subtraction/Worksheet-8.pdf",
      class: "KG",
      subject: "Math",
      chapter: "Subtraction",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-1.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-10.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-2.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-3.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-4.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-5.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-6.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-7.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-8.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Practice%20letters%20in%20Space/Worksheet-9.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Practice letters in Space",
      set: "9",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Rhyming%20words/Worksheet%20-1%20.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Rhyming words",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Rhyming%20words/Worksheet-2.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Rhyming words",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Rhyming%20words/Worksheet-3.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Rhyming words",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Rhyming%20words/Worksheet-4.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Rhyming words",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-1.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-10.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-2.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-3.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-4.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-5.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-6.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-7.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-8.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/The%20alphabet/Worksheet-9.pdf",
      class: "LKG",
      subject: "English",
      chapter: "The alphabet",
      set: "9",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Things%20we%20eat/Worksheet-1.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Things we eat",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Things%20we%20eat/Worksheet-2.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Things we eat",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Things%20we%20eat/Worksheet-3.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Things we eat",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Things%20we%20eat/Worksheet-4.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Things we eat",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Things%20we%20eat/Worksheet-5.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Things we eat",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Things%20we%20eat/Worksheet-6.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Things we eat",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-1.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-2.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-3.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-4.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-5.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-6.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-7.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/English/Transport/Worksheet-8.pdf",
      class: "LKG",
      subject: "English",
      chapter: "Transport",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20five/Worksheet%20-1%20.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto five",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20five/Worksheet%20-2%20.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto five",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20five/Worksheet%20-3%20.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto five",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20five/Worksheet%20-4%20.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto five",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20five/Worksheet%20-5.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto five",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20five/Worksheet%20-6.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto five",
      set: "",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-1.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-2.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-3.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-4.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-5.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-6.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-7.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-8.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20ten/Worksheet-9.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto ten",
      set: "9",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20twenty/Worksheet-1.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto twenty",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20twenty/Worksheet-2.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto twenty",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20twenty/Worksheet-3.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto twenty",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20twenty/Worksheet-4.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto twenty",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Counting%20upto%20twenty/Worksheet-5.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Counting upto twenty",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-1.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-2.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-3.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-4.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-5.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-6.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-7.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-8.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "8",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/More_%20Less%20_%20Big%20_small/Worksheet-9.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "More_ Less _ Big _small",
      set: "9",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-1.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "1",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-2.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "2",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-3.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "3",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-4.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "4",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-5.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "5",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-6.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "6",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-7.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "7",
      type: "worksheet",
    },
    {
      url:
        "https://pdf-2.s3.ap-south-1.amazonaws.com/LKG/Math/Shapes/Worksheet-8.pdf",
      class: "LKG",
      subject: "Math",
      chapter: "Shapes",
      set: "8",
      type: "worksheet",
    },
  ],
};
