export default function parseClassesFromRC(RC, standard) {
  if (RC && RC.files) {
    const subjects = new Set();

    RC.files.forEach((inst) => {
      if (inst.class === standard) {
        subjects.add(inst.subject);
      }
    });
    return [...subjects];
  }
  return [];
}
