export default function parseClassesFromRC(RC) {
  if (RC && RC.files) {
    const classes = new Set();

    RC.files.forEach((inst) => {
      classes.add(inst.class);
    });
    return [...classes];
  }
  return [];
}
