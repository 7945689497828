import { makeObservable, observable, computed, action, autorun } from "mobx";
import checkIfCanDownload from "../utils/checkIfCanDownload";
import { serverString } from "../utils/config";
import store from "store";
import axios from "axios";
import { FSStoreInstance } from "./FSstore";

class UserDataStore {
  canDownload;
  downloadCount;
  phoneNumber;
  loggedIn;
  shared;
  otp;
  noBlocker;

  constructor() {
    this.canDownload = store.get("canDownload") || true;
    this.downloadCount = store.get("downloadCount") || 0;
    this.phoneNumber = store.get("phoneNumber") || null;
    this.loggedIn = store.get("loggedIn") || false;
    this.shared = store.get("shared") || false;
    this.otp = null;
    this.noBlocker = store.get("noBlocker") || false;

    makeObservable(this, {
      canDownload: observable,
      downloadCount: observable,
      phoneNumber: observable,
      loggedIn: observable,
      shared: observable,
      otp: observable,
      noBlocker: observable,

      downloadFromLink: action,
      sendOtpToNo: action,
      shareAction: action,
    });
    autorun(() => {
      this.canDownload = checkIfCanDownload();

      store.set("canDownload", this.canDownload);
      store.set("downloadCount", this.downloadCount);
      store.set("phoneNumber", this.phoneNumber);
      store.set("loggedIn", this.loggedIn);
      store.set("shared", this.shared);
      store.set("noBlocker", this.noBlocker);
    });
  }

  downloadFromLink(link, standard, subject, chapter, ind) {
    console.log("download", link);
    var element = document.createElement("a");
    element.setAttribute("href", link);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    this.downloadCount = Number(this.downloadCount) + 1;

    const fireEvent = (title, body) => FSStoreInstance.fireEvent(title, body);

    fireEvent("Download Done", { class: standard, subject, chapter, ind });
  }
  storePhoneNumber(phoneNumber) {
    console.log("stored");
    this.phoneNumber = phoneNumber;

    store.set("phoneNumber", phoneNumber);
  }
  sendOtpToNo(phoneNumber) {
    console.log("sending OTP to", phoneNumber);

    axios
      .post(`${serverString}/prod/teacherSignup`, {
        phone_number: phoneNumber,
        name: " ",
      })
      .then((res) => {
        this.otp = res.data.verification_code;

        //console.log(res.data.verification_code);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  register(name, entity) {
    // console.log("registering",name,this.phoneNumber,entity);
    axios
      .post(`${serverString}/prod/caseStudyTeachers`, {
        phone_number: this.phoneNumber,
        name,
        entity,
      })
      .then((res) => {
        console.log("registered");
        this.canDownload = true;
        this.loggedIn = true;
        store.set("canDownload", true);
        store.set("loggedIn", true);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  shareAction() {
    store.set("noBlocker", true);
    this.noBlocker = true;
    store.set("shared", true);
    this.shared = true;
  }
}

const userDataInstance = new UserDataStore();

export { userDataInstance };
