import { observer } from "mobx-react";
import LoginWithPhoneNumber from "../components/blockerPage/LoginWithPhoneNumber";
import Share from "../components/blockerPage/Share.js";
import { userDataInstance } from "../stores/userDataStore";

const BlockerPage = observer(() => {
    if (userDataInstance.loggedIn === false) {
        return <LoginWithPhoneNumber />;
    } else {
        return <Share />;
    }
});

export default BlockerPage;
