import React from "react";
import { useHistory } from "react-router";
import ViewBoxContainer from "../../Layout/ViewBoxContainer";
import { FSStoreInstance } from "../../stores/FSstore";
import warningLogo from "../../assets/images/warning.png";
const ErrorCookies = () => {
    const history = useHistory();
    const fetchRC = () => {
        FSStoreInstance.fetchRC();
    };
    //console.log(location);
    const goBack = () => {
        fetchRC();
        history.goBack();
    };
    return (
        <ViewBoxContainer>
            <div className="w-full h-full my-10 flex flex-col items-center">
                <img style={{ width: "200px" }} src={warningLogo} />

                <p
                    style={{
                        color: "#fc4674",
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginTop: "50px",
                    }}
                >
                    Error! Cookies disabled
                </p>

                <p
                    style={{
                        color: "#555555",
                        fontSize: "20px",
                        margin: "20px 44px",
                    }}
                    className="text-center"
                >
                    Please enable cookies from settings or use this in another
                    browser
                </p>

                <button
                    style={{
                        backgroundColor: "#fc4674",
                        color: "white",
                        fontSize: "20px",
                        padding: "5px",
                        borderRadius: "10px",
                    }}
                    onClick={goBack}
                >
                    Reload
                </button>
            </div>
        </ViewBoxContainer>
    );
};

export default ErrorCookies;
