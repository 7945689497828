import { observer } from "mobx-react";

import eyeIcon from "../../assets/images/eye_icon.png";
import getDownloadCount from "../../utils/getDownloadCount";

const DownloadCounter = observer(() => {
  return (
    <div>
      <div
        style={{ backgroundColor: "#ffa768", width: "fit-content" }}
        className=" mx-3 my-4 w-10/12 px-3 py-1 rounded-md text-white flex justify-center items-center"
      >
        <img style={{ width: "16px" }} className=" mr-2" src={eyeIcon} />
        <p style={{ fontSize: "12px" }}>
          {getDownloadCount()} - Worksheets downloaded{" "}
        </p>
      </div>
    </div>
  );
});

export default DownloadCounter;
