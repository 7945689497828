import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import { FSStoreInstance } from "../stores/FSstore";
import useUpdateChecker from "../customHooks/useUpdateCheck";
import parseLinkFromRC from "../utils/parsers/parseLinkFromRC";
import { Link, useHistory, useParams } from "react-router-dom";
import { userDataInstance } from "../stores/userDataStore";
import { DefaultConfigValues, DefaultDownloadPageValue } from "../utils/Consts";
import pdfLogo from "../assets/images/pdf.png";

const Home = observer(() => {
  const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
  const canDownload = useUpdateChecker(userDataInstance.canDownload);
  let DownloadPageValue = useUpdateChecker(FSStoreInstance.DownloadPage);
  let ConfigValue = useUpdateChecker(FSStoreInstance.ConfigValues);

  if (DownloadPageValue === undefined || DownloadPageValue === null) {
    DownloadPageValue = DefaultDownloadPageValue;
  }
  if (ConfigValue === undefined || ConfigValue === null) {
    ConfigValue = DefaultConfigValues;
  }

  const { standard, subject, chapter, type, ind, showIndex } = useParams();
  const link = parseLinkFromRC(rcValue, standard, subject, chapter, ind);
  const history = useHistory();

  const fireEvent = (title, body) => FSStoreInstance.fireEvent(title, body);

  useEffect(() => {
    fireEvent("Download Page Opened", {
      class: standard,
      subject,
      chapter,
      ind,
    });
  }, []);

  const download = (link) => userDataInstance.downloadFromLink(link);

  let timerId = undefined;
  let backTimerId = undefined;
  let downloadDone = false;
  const countRef = useRef();

  const startDelay = ConfigValue.startDelay;
  const backDelay = ConfigValue.backDelay;

  const startCount = () => {
    if (countRef.current !== undefined) {
      backTimerId = setTimeout(() => {
        history.goBack();
      }, backDelay * 1000);

      timerId = setTimeout(function loop() {
        const currCount = countRef.current.innerHTML;

        if (currCount > 0) {
          countRef.current.innerHTML = currCount - 1;
          timerId = setTimeout(loop, 1000);
        } else {
          download(link, standard, subject, chapter, ind);
          downloadDone = true;

          //downloadFile(subject,chapter,set,type);
        }
      }, 0);
    }
  };

  const stopCount = () => {
    clearInterval(timerId);
    clearInterval(backTimerId);
    console.log("stop count");
  };

  useEffect(() => {
    if (rcValue !== undefined) {
      //console.log(canDownload);
      if (canDownload) {
        startCount();

        //console.log(backDelay);
      }

      //fireEvent("Download Page Opened", { subject, chapter, set, type });

      return () => {
        if (!downloadDone) {
          stopCount();
          console.log("Download stoped");
          //   fireEvent("Download stoped", {
          //     subject,
          //     chapter,
          //     set,
          //     type,
          //   });
        }
      };
    }
  }, [rcValue]);
  return (
    <ViewBoxContainer>
      <div style={{ position: "relative", height: "85vh" }} className="w-full">
        <div
          style={{
            backgroundColor: "#afafaf",
            width: "80px",
            height: "40px",
            position: "absolute",
            top: "12px",
            left: "12px",
          }}
          className="text-white rounded-lg flex items-center justify-center"
          onClick={() => {
            history.goBack();
          }}
        >
          &lt;- Back
        </div>
        <div className="w-full flex flex-col items-center">
          <img
            src={DownloadPageValue.image}
            className="w-25 h-24 rounded-full my-2"
          />
          <p
            style={{
              color: "#fc4674",
              minHeight: "50px",
              fontSize: "16px",
            }}
            className=" font-bold w-6/12 text-center my-2"
          >
            {DownloadPageValue.title}
          </p>
          <p
            style={{
              minHeight: "50px",
              fontSize: "12px",
            }}
            className="text-center text-gray-500 text-sm w-10/12 my-2"
          >
            {DownloadPageValue.body}
          </p>
          <a
            style={{
              backgroundColor: "#fc4674",
              fontSize: "16px",
            }}
            className="px-4 py-2 text-white rounded-md"
            target="_blank"
            href={DownloadPageValue.cta_url}
          >
            {DownloadPageValue.cta}
          </a>
        </div>

        <div
          style={{
            boxShadow: "0px -13px 16px #bdbdbd",
            position: "absolute",
            bottom: "0",
          }}
          className=" w-full bg-white pt-5 flex flex-col justify-center items-center  pb-5 mb-5"
        >
          <p className="w-11/12 font-semibold text-gray-500">
            Starting download in <span ref={countRef}> {startDelay} </span>
          </p>
          <div className="w-11/12 bg-gray-200 flex items-center p-3 rounded-md my-1  ">
            <img src={pdfLogo} className="w-12 h-12 my-1" />
            <div>
              <p className="text-lg font-semibold text-gray-500 my-1"></p>
              <p className="text-sm text-gray-500">
                {" "}
                {type === "worksheet" ? "Worksheet" : "Anser Key"}-
                {Number(showIndex)}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ViewBoxContainer>
  );
});

export default Home;
