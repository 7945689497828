import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { userDataInstance } from "../../stores/userDataStore";
import { observer } from "mobx-react";

const ShareButton = observer(
    ({ url, text, displayText, file, clickFunction }) => {
        const shared = userDataInstance.shared;
        const history = useHistory();
        const shareAction = () => userDataInstance.shareAction("blank");
        // if (shared) {
        //     history.goBack();
        // }
        const share = async () => {
            // canShare is not supported in opera and safari

            if (navigator.share !== undefined) {
                try {
                    await navigator.share({
                        title: "homework-app",
                        text: text,
                        url: url,
                        files: [file],
                    });
                    // dumpEvent("Referral_Instantiated", {
                    //     time: Date.now(),
                    // });
                    //console.log("Successful share");
                } catch (error) {
                    //console.log("Error sharing", error);
                }
            }
            shareAction();
        };

        return (
            <button
                onClick={() => {
                    share();
                    clickFunction();
                }}
                style={{ backgroundColor: "#fc4674" }}
                className="w-4/12 text-center px-3 py-2 rounded-lg text-lg text-white unselectable"
            >
                {displayText}
            </button>
        );
    }
);

export default ShareButton;
