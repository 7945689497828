import React, { useEffect } from "react";
import { observer } from "mobx-react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import { FSStoreInstance } from "../stores/FSstore";
import useUpdateChecker from "../customHooks/useUpdateCheck";
import parseChaptersFromRC from "../utils/parsers/parseChaptersFromRC";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import DownloadCounter from "../components/common/DownLoadCounter";

const SubjectPage = observer(() => {
  const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
  const { standard, subject } = useParams();
  const chapters = parseChaptersFromRC(rcValue, standard, subject);

  const fireEvent = (title, body) => FSStoreInstance.fireEvent(title, body);

  useEffect(() => {
    fireEvent("Subject Page Opened", { class: standard, subject: subject });
  }, []);

  return (
    <ViewBoxContainer>
      <div>
        {rcValue.files !== undefined ? (
          <div>
            <DownloadCounter />
            <div
              style={{ color: "#42c6a3", fontSize: "16px" }}
              className="font-normal font-semibold mx-3 my-2"
            >
              <Link to={`/class/${standard}`}>{standard}</Link>&gt;{" "}
              <Link to={`/subject/${standard}/${subject}`}>{subject}</Link>
            </div>
            <div>
              {chapters.map((inst, ind) => {
                return (
                  <div
                    key={ind}
                    style={{
                      color: "#555555",
                      backgroundColor: "#f2f2f2",
                    }}
                    className="flex items-center m-3 py-4 px-3 rounded-lg font-semibold"
                  >
                    <Link to={`/chapter/${standard}/${subject}/${inst}`}>
                      {ind + 1}. {inst}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          "loading"
        )}
      </div>
    </ViewBoxContainer>
  );
});

export default SubjectPage;
