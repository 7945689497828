export default function parseClassesFromRC(RC, standard, subject, chapter) {
  if (RC && RC.files) {
    const files = new Set();
    let count = 0;

    RC.files.forEach((inst, ind) => {
      if (
        inst.class === standard &&
        inst.subject === subject &&
        inst.chapter === chapter
      ) {
        files.add(inst);
      }
    });
    return [...files];
  }
  return [];
}
