export default function parseClassesFromRC(RC, standard, subject) {
  if (RC && RC.files) {
    const chapters = new Set();

    RC.files.forEach((inst) => {
      if (inst.class === standard && inst.subject === subject) {
        chapters.add(inst.chapter);
      }
    });
    return [...chapters];
  }
  return [];
}
