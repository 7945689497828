import React, { useEffect } from "react";
import { observer } from "mobx-react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import { FSStoreInstance } from "../stores/FSstore";
import useUpdateChecker from "../customHooks/useUpdateCheck";
import parseClassesFromRC from "../utils/parsers/parseClassesFromRC";
import { Link } from "react-router-dom";
import DownloadCounter from "../components/common/DownLoadCounter";
import { gradients } from "../utils/Consts";

const Home = observer(() => {
  const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
  const classes = parseClassesFromRC(rcValue);

  const fireEvent = (title, body) => FSStoreInstance.fireEvent(title, body);

  useEffect(() => {
    fireEvent("Home Page Opened", {});
  }, []);
  return (
    <ViewBoxContainer>
      <div>
        {rcValue.files !== undefined ? (
          <div>
            <DownloadCounter />
            <p
              style={{ color: "#42c6a3", fontSize: "16px" }}
              className="font-bold mx-3 "
            >
              Select a grade
            </p>
            <div className="grid grid-cols-2">
              {classes.map((inst, ind) => {
                return (
                  <div
                    key={ind}
                    className="p-2 flex justify-center items-center h-36 w-full "
                  >
                    <Link
                      to={`/class/${inst}`}
                      className=" w-full h-full text-white font-bold flex justify-center items-center rounded-md"
                      style={{
                        fontSize: "40px",
                        backgroundImage: `linear-gradient(to right bottom,${gradients[ind].from}, ${gradients[ind].to})`,
                      }}
                    >
                      {inst}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          "loading"
        )}
      </div>
    </ViewBoxContainer>
  );
});

export default Home;
