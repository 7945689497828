import { startMillis } from "./Consts";
import store from "store";
const getDownloadCount = () => {
  const n1Minutes = Math.floor((Date.now() - startMillis) / 60000);
  const noOfDownloads = 1;
  const downloadCount =
    1768 + noOfDownloads * n1Minutes + store.get("downloadCount");
  return downloadCount;
};

export default getDownloadCount;
