import React, { useEffect } from "react";
import { observer } from "mobx-react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import { FSStoreInstance } from "../stores/FSstore";
import useUpdateChecker from "../customHooks/useUpdateCheck";
import parseFilesFromRC from "../utils/parsers/parseFilesFromRC";
import { Link, useParams } from "react-router-dom";
import DownloadCounter from "../components/common/DownLoadCounter";
import pdfLogo from "../assets/images/pdf.png";
import checkIfCanDownload from "../utils/checkIfCanDownload";
import { userDataInstance } from "../stores/userDataStore";

const Home = observer(() => {
  const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
  const { standard, subject, chapter } = useParams();
  const files = parseFilesFromRC(rcValue, standard, subject, chapter);
  //console.log(files);

  let WorksheetIndex = 1;
  let AnswersheetIndex = 1;

  const fireEvent = (title, body) => FSStoreInstance.fireEvent(title, body);

  useEffect(() => {
    fireEvent("Chapter Page Opened", { class: standard, subject, chapter });
  }, []);
  return (
    <ViewBoxContainer>
      <div>
        {rcValue.files !== undefined ? (
          <div>
            <DownloadCounter />
            <div
              style={{ color: "#42c6a3", fontSize: "16px" }}
              className="font-normal font-semibold mx-3 my-2"
            >
              <Link to={`/class/${standard}`}>{standard}</Link>&gt;{" "}
              <Link to={`/subject/${standard}/${subject}`}>{subject}</Link>&gt;{" "}
              <Link to={`/chapter/${standard}/${subject}/${chapter}`}>
                {chapter}
              </Link>
            </div>
            <div className="grid grid-cols-2">
              {files.map((inst, ind) => {
                //console.log(inst.type);
                if (inst.type === "worksheet") {
                  return (
                    <div
                      className="w-full flex items-center justify-center py-3 px-3"
                      style={{ margin: "", height: "180px" }}
                      key={ind}
                    >
                      <Link
                        to={
                          checkIfCanDownload(
                            userDataInstance.downloadCount,
                            userDataInstance.loggedIn,
                            userDataInstance.shared,
                            userDataInstance.noBlocker
                          )
                            ? `/download/${standard}/${subject}/${chapter}/${inst.type}/${ind}/${inst.set}`
                            : `/blocker`
                        }
                        className=" w-full h-full flex flex-col justify-center items-center rounded-md"
                        style={{
                          backgroundColor: "#f2f2f2",
                        }}
                        key={ind}
                      >
                        <img
                          src={pdfLogo}
                          style={{ width: "64px", height: "64px" }}
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#555555",
                            fontWeight: "bold",
                            margin: "10px 0",
                          }}
                        >{`Worksheet-${inst.set}`}</p>
                      </Link>
                    </div>
                  );
                } else if (inst.type === "answersheet") {
                  return (
                    <div
                      className="w-full flex items-center justify-center py-3 px-3"
                      style={{ margin: "", height: "180px" }}
                      key={ind}
                    >
                      <Link
                        to={
                          checkIfCanDownload(
                            userDataInstance.downloadCount,
                            userDataInstance.loggedIn,
                            userDataInstance.shared,
                            userDataInstance.noBlocker
                          )
                            ? `/download/${standard}/${subject}/${chapter}/${inst.type}/${ind}/${inst.set}`
                            : `/blocker`
                        }
                        className=" w-full h-full flex flex-col justify-center items-center rounded-md"
                        style={{
                          backgroundColor: "#d6ffdb",
                        }}
                        key={ind}
                      >
                        <img
                          src={pdfLogo}
                          style={{ width: "64px", height: "64px" }}
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#555555",
                            fontWeight: "bold",
                            margin: "10px 0",
                          }}
                        >{`Answer Key-${inst.set}`}</p>
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : (
          "loading"
        )}
      </div>
    </ViewBoxContainer>
  );
});

export default Home;
