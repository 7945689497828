import { observer } from "mobx-react";
import React from "react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import Image404 from "../assets/images/404.png";
import { Link } from "react-router-dom";

const Page404 = observer(() => {
    return (
        <ViewBoxContainer>
            <div className="w-full h-full my-10 flex flex-col items-center">
                <img className="w-full" src={Image404} />

                <p
                    style={{
                        color: "#fc4674",
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginTop: "50px",
                    }}
                >
                    Oops! Page not found
                </p>

                <p
                    style={{
                        color: "#555555",
                        fontSize: "20px",
                        margin: "20px 31px",
                    }}
                    className="text-center"
                >
                    We can’t find the page you were looking for. Please go back
                    to homepage and try again
                </p>

                <Link
                    to="/"
                    style={{
                        backgroundColor: "#fc4674",
                        color: "white",
                        fontSize: "20px",
                        padding: "5px",
                        borderRadius: "10px",
                    }}
                >
                    Go to homepage
                </Link>
            </div>
        </ViewBoxContainer>
    );
});

export default Page404;
