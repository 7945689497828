import { Integrations } from "@sentry/tracing";
// firebase configs
const prodFirebaseConfig = {
  apiKey: "AIzaSyDyTLLLpU40gHk6wxOeSFZV7-isyzj7iNI",
  authDomain: "worksheet-prod-ae533.firebaseapp.com",
  projectId: "worksheet-prod-ae533",
  storageBucket: "worksheet-prod-ae533.appspot.com",
  messagingSenderId: "918935386053",
  appId: "1:918935386053:web:24be5b092610b02361bdac",
  measurementId: "G-DRWTE4HC8W",
};
const testFirebaseConfig = {
  apiKey: "AIzaSyAcsV77AiEgG2al0CNDY8c5gJuQi30tNR8",
  authDomain: "worksheet-dev-5a6b2.firebaseapp.com",
  projectId: "worksheet-dev-5a6b2",
  storageBucket: "worksheet-dev-5a6b2.appspot.com",
  messagingSenderId: "819814155126",
  appId: "1:819814155126:web:6cc4ab77ead3522e4d7057",
  measurementId: "G-YR5N4Z98HT",
};

// server configs
const serverStringProd = "https://homeworkapp.ai/root2/ts";
const serverStringTest = "https://homeworkapp.ai/test/ts";

const prodSentryConfig = {
  dsn:
    "https://d1afe7648f3a4a759738747b0e34725e@o503779.ingest.sentry.io/5694999",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
};
const testSentryConfig = {
  dsn:
    "https://ba476640d4ae4b80a6e7a7edca4a6ab0@o503779.ingest.sentry.io/5694984",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
};

// set env as "test" for QA
// set env as "Prod" for Production

const env = "Prod";

export const firebaseConfig =
  env === "Prod" ? prodFirebaseConfig : testFirebaseConfig;

export const serverString =
  env === "Prod" ? serverStringProd : serverStringTest;

export const sentryConfig =
  env === "Prod" ? prodSentryConfig : testSentryConfig;
