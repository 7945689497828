import { FSStoreInstance } from "../stores/FSstore";

const checkIfCanDownload = (downloadCount, loggedIn, shared, noBlocker) => {
  if (noBlocker) {
    return true;
  }

  let loginBlockerAtCount = 1;
  let shareBlockerAtCount = 3;

  if (FSStoreInstance.ConfigValues !== undefined) {
    loginBlockerAtCount = FSStoreInstance.ConfigValues.loginBlocker;
    shareBlockerAtCount = FSStoreInstance.ConfigValues.shareBlocker;

    //console.log(loginBlockerAtCount, shareBlockerAtCount);
  }

  if (!loggedIn) {
    if (loginBlockerAtCount === -1) {
      return true;
    } else if (downloadCount >= loginBlockerAtCount) {
      return false;
    } else {
      return true;
    }
  } else {
    if (shareBlockerAtCount === -1) {
      return true;
    } else if (!shared && downloadCount >= shareBlockerAtCount) {
      return false;
    } else {
      return true;
    }
  }
};

export default checkIfCanDownload;
