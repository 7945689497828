import React, { useEffect, useState } from "react";
import ViewBoxContainer from "../../Layout/ViewBoxContainer";
import ShareButton from "./ShareButton";
import store from "store";
import shareImg from "../../assets/images/shareShow.png";
import { observer } from "mobx-react";
import { FSStoreInstance } from "../../stores/FSstore";
import { useHistory } from "react-router";

const Share = observer(() => {
    let sharePageStuff = FSStoreInstance.SharePage;

    const history = useHistory();
    const [file, setFile] = useState();
    const [referralLoad, setReferralLoad] = useState(true);
    const [shareClicked, setShareClicked] = useState(false);

    const loadGif = async () => {
        if (navigator.share !== undefined) {
            const response = await fetch(sharePageStuff.msgData.msg_img_link);
            const blob = await response.blob();
            setFile(new File([blob], "share.gif", { type: blob.type }));
            //console.log("fetch done");
        }
    };
    useEffect(() => {
        sharePageStuff = FSStoreInstance.SharePage;
        loadGif();
        setTimeout(() => {
            setReferralLoad(false);
        }, 300);
    }, [FSStoreInstance.SharePage]);
    return (
        <ViewBoxContainer>
            {!referralLoad ? (
                <div className="w-full flex flex-col items-center mb-64 space-4 relative">
                    <div
                        style={{
                            backgroundColor: "#afafaf",
                            width: "80px",
                            height: "40px",
                            position: "absolute",
                            top: "12px",
                            left: "12px",
                            opacity: shareClicked ? 1 : 0,
                            pointerEvents: shareClicked ? "all" : "none",
                        }}
                        className="text-white rounded-lg flex items-center justify-center"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        &lt;- Back
                    </div>
                    <p
                        style={{ color: "#fc4674" }}
                        className="text-lg font-bold w-10/12 text-center mt-16 "
                    >
                        {sharePageStuff.pageData.page_title}
                    </p>
                    <img src={shareImg} className="w-6/12 h-auto " />

                    <p className="text-center text-gray-500 text-xl w-10/12 font-bold mb-5">
                        {sharePageStuff.pageData.page_text}
                    </p>

                    <ShareButton
                        url={
                            sharePageStuff.msgData.msg_url +
                            store.get("phoneNumber")
                        }
                        clickFunction={() => {
                            setShareClicked(true);
                            console.log("clicked");
                        }}
                        displayText={sharePageStuff.pageData.cta}
                        text={sharePageStuff.msgData.msg_text}
                        displayImage={sharePageStuff.msgData.msg_img_link}
                        file={file}
                    />
                </div>
            ) : (
                ""
            )}
        </ViewBoxContainer>
    );
});

export default Share;
