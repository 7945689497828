export default function parseClassesFromRC(
  RC,
  standard,
  subject,
  chapter,
  ind
) {
  console.log({ standard, subject, chapter, ind });
  let url = "";
  if (RC && RC.files) {
    let count = 0;

    for (let i = 0; i < RC.files.length; i++) {
      const inst = RC.files[i];

      if (
        inst.class === standard &&
        inst.subject === subject &&
        inst.chapter === chapter
      ) {
        console.log(count, Number(ind));
        if (count === Number(ind)) {
          url = inst.url;
          break;
        }
        count += 1;
      }
    }
  }
  console.log(url);
  return url;
}
